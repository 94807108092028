export function SearchIcon(props: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" {...props}>
            <g id="Group_150698" data-name="Group 150698" transform="translate(0 -7)">
                <rect id="Rectangle_24197" data-name="Rectangle 24197" width="40" height="40" transform="translate(0 7)" fill="#fff" opacity="0" />
                <path id="Path_198416" data-name="Path 198416" d="M21,21l-5.2-5.2m0,0A7.5,7.5,0,1,0,5.2,15.8,7.5,7.5,0,0,0,15.8,15.8Z" transform="translate(8 15.001)" fill="none" stroke={props.color || "#babdcc"} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
            </g>
        </svg>
    )
}
