import { Divider } from "@mui/material";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { Box } from "@mui/material";
import SearchBar from "../../../shared/search-bar";
import { IndexMaster } from "@mprofit/shared/src/constants/assetMasters";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SharedConstants, SharedRedux, SharedUtilities } from "@mprofit/shared";
import ButtonText from "../../../shared/button-text";
import ButtonPrimaryCTA from "../../../shared/button-primary-cta";
import { useAppDispatch, useAppSelector } from "packages/web/src/redux/hooks";
import { SearchHeader } from "./index";
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

export const SingleIndexView = ({
  initialActiveSingleIndex,
  handleClose,
}: {
  initialActiveSingleIndex: SharedConstants.WatchlistItem | undefined;
  handleClose: () => void;
}) => {
  const indicesMaster = useAppSelector(
    SharedRedux.Dashboard.Selectors.selectIndicesForPerformanceMaster
  );

  const [searchTerm, setSearchTerm] = useState("");

  const filteredIndices = useMemo(
    () => SharedUtilities.filterItems(searchTerm, indicesMaster, ["IndexName"]),
    [searchTerm, indicesMaster]
  );

  const [activeIndexItem, setActiveIndexItem] = useState<
    IndexMaster | undefined
  >(
    initialActiveSingleIndex
      ? {
          MProfitCode: initialActiveSingleIndex.MProfitCode,
          IndexName: initialActiveSingleIndex.AssetName,
        }
      : undefined
  );
  const dispatch = useAppDispatch();

  const onSave = useCallback(() => {
    if (!activeIndexItem?.MProfitCode || !activeIndexItem?.IndexName)
      return handleClose();

    const indexPayload = {
      IndexItem: {
        AssetName: activeIndexItem.IndexName,
        MProfitCode: activeIndexItem.MProfitCode,
      },
    };

    dispatch(
      SharedRedux.Dashboard.Actions.setXIRRBenchmarkSingleIndex(indexPayload)
    );
    handleClose();
  }, [activeIndexItem, dispatch]);

  return (
    <>
      <SearchHeader
        title="Select Index"
        subtitle="Select an index to compare your XIRR"
      >
        <Box>
          <SearchBar
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            isNonRoundedBorders={true}
            searchIconColor="#515466"
            sxProps={{
              "& .MuiInputBase-root": {
                height: "48px",
                paddingLeft: 0,
                borderRadius: 0,
              },
              "& ::placeholder": {
                color: "#515466",
                opacity: "100%",
              },
            }}
          />
        </Box>
      </SearchHeader>
      <OverlayScrollbarsComponent style={{flex: 1}} defer options={{ scrollbars: { autoHide: 'scroll' } }}>
      <RadioGroup
        sx={{
          flexWrap: "nowrap"
        }}
      >
        {filteredIndices.map((indexItem, index) => (
          <Box
            key={index}
            sx={{
              "&:hover": { cursor: "pointer", backgroundColor: "#EAF4DF" },
            }}
            onClick={() => setActiveIndexItem(indexItem)}
          >
            <FormControlLabel
              value={indexItem.MProfitCode}
              sx={{
                marginRight: "0px",
                color: "#12131A",
                fontSize: "18px",
                padding: "10px",
                "& .MuiFormControlLabel-label": { fontSize: "18px" },
              }}
              control={
                <Radio
                  sx={{
                    marginLeft: "14px",
                    color: "#BABDCC",
                    "&.Mui-checked": {
                      color: "#5F854C",
                    },
                  }}
                  checked={
                    activeIndexItem?.MProfitCode === indexItem.MProfitCode
                  }
                  onChange={() => setActiveIndexItem(indexItem)}
                />
              }
              label={indexItem.IndexName}
            />
            <Divider sx={{ width: "100%", margin: "auto" }} />
          </Box>
        ))}
      </RadioGroup>
      </OverlayScrollbarsComponent>
      <Box
        sx={{
          display: "flex",
          padding: "20px",
          justifyContent: "flex-end",
          borderTop: "1px solid #EBECF2",
        }}
      >
        <ButtonText
          buttonText="Cancel"
          handleClick={() => handleClose()}
          buttonSize="large"
        />
        <Box ml={"10px"}>
          <ButtonPrimaryCTA
            buttonText="Select"
            handleClick={onSave}
            disabled={!activeIndexItem}
          />
        </Box>
      </Box>
    </>
  );
};
