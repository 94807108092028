export function TooltipDelete(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="Group_148769" data-name="Group 148769" transform="translate(-3 -3)">
        <rect id="Rectangle_6557" data-name="Rectangle 6557" width="24" height="24" transform="translate(3 3)" fill="#fff" opacity="0" />
        <path id="Path_198421" data-name="Path 198421" d="M12.767,7.788l-.284,7.384m-3.929,0L8.271,7.788M16.45,5.154c.281.043.56.088.839.136m-.839-.135-.876,11.39a1.846,1.846,0,0,1-1.841,1.7H7.306a1.846,1.846,0,0,1-1.841-1.7L4.589,5.154m11.861,0q-1.42-.215-2.854-.326M3.75,5.29c.279-.048.558-.094.839-.135m0,0q1.42-.215,2.854-.326m6.154,0V4.077a1.786,1.786,0,0,0-1.715-1.806q-1.362-.044-2.724,0A1.787,1.787,0,0,0,7.442,4.077v.752m6.154,0a39.931,39.931,0,0,0-6.154,0" transform="translate(4.75 5.25)" fill="none" stroke="#c94c40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      </g>
    </svg>


  );
}
