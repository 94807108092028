import { Box } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react'
import CustomTypography from '../../customTypography';
import { ArrowDown } from 'packages/web/src/assets/icons/ArrowDown';
import { ControlledMonth, SetMonthProps } from '../month';
import { ControlledYear, SetYearProps } from '../year';
import { SharedUtilities } from '@mprofit/shared';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';


interface ICustomDAteProps {
    setDate?: (value: Date) => void;
    date?: Date;
}

enum CustomDateMode {
    MonthSelect = 1,
    YearSelect = 2,
    DateSelect = 3
}

export default function CustomDateSelector({ setDate, date }: ICustomDAteProps) {

    const handleChangeDate = (val: any) => {
        if (setDate) setDate(val);
    }

    const now = new Date();

    const currentYearNumber = now.getFullYear();
    const currentMonth = now.getMonth() + 1;

    const [selectedYearNumber, setSelectedYearNumber] = useState<number>(currentYearNumber);
    const [selectedMonthNumber, setSelectedMonthNumber] = useState<number>(currentMonth);

    const [mode, setMode] = useState<CustomDateMode>(CustomDateMode.DateSelect);

    const [selectedMonth, setSelectedMonth] = useState<SetMonthProps | undefined>(undefined);

    const [selectedYear, setSelectedYear] = useState<SetYearProps | undefined>(undefined);

    const onActiveStartDateChange = (newActiveStartDate: Date | null) => {
        if (newActiveStartDate) {
            onSelectMonthNumber(newActiveStartDate.getMonth() + 1);
            onSelectYearNumber(newActiveStartDate.getFullYear());
        }
    }
    //#region Month Select

    const onSelectMonthNumber = (monthNumber: number) => {
        setSelectedMonthNumber(monthNumber);

        onMonthChange(monthNumber, selectedYearNumber);
    }


    const onMonthChange = (monthNumber: number, yearNumber: number, isMount: boolean = false) => {
        var newSelectedMonth = SharedUtilities.getMonthSelectedItem(monthNumber, yearNumber);

        if (newSelectedMonth) {
            setSelectedMonth(newSelectedMonth);

            if (!isMount) {
                setMode(CustomDateMode.DateSelect);
            }
        }
    }

    useEffect(() => {
        onMonthChange(selectedMonthNumber, selectedYearNumber, true);
    }, []);

    //#endregion

    //#region Year Select

    const yearsList = useMemo(SharedUtilities.getYearListForPeriodSelection, []);

    const onSelectYearNumber = (yearNumber: number) => {
        setSelectedYearNumber(yearNumber);

        onYearChange(yearNumber);
    }

    const onYearChange = (yearNumber: number, isMount: boolean = false) => {
        var newSelectedYear = SharedUtilities.getYearSelectedItem(yearsList, yearNumber);

        if (newSelectedYear) {
            setSelectedYear(newSelectedYear);

            if (!isMount) {
                setMode(CustomDateMode.DateSelect);
            }
        }
    }

    useEffect(() => {
        onYearChange(selectedYearNumber, true);
    }, []);

    //#endregion

    useEffect(() => {
        if (date) {
            const newMonth = date.getMonth() + 1;
            const newYear = date.getFullYear();
            
            setSelectedMonthNumber(newMonth);
            setSelectedYearNumber(newYear);
            
            onMonthChange(newMonth, newYear, true);
            onYearChange(newYear, true);
        }
    }, [date]);

    const activeStartDate = selectedMonth && selectedYear && new Date(selectedYear.yearNumber, selectedMonth.monthNumber - 1, 1);


    return (
        <>
            {
                mode == CustomDateMode.DateSelect &&
                <Box className="year-month __Container" sx={{ display: 'flex', position: 'absolute', zIndex: 2, }}>
                    <Box className="month__buttons-container" sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => { setMode(CustomDateMode.MonthSelect) }}>
                        <CustomTypography variant="Regular20">{selectedMonth?.monthName}</CustomTypography>
                        <Box sx={{ width: '32px', height: '32px', display: 'flex', justifyContent: 'center', alignItems: 'center', ":hover": { background: '#F9F9F9' } }}>
                            <ArrowDown />
                        </Box>
                    </Box>
                    <Box className="year__buttons-container" sx={{ display: 'flex', alignItems: 'center', marginLeft: '16px', cursor: 'pointer' }} onClick={() => { setMode(CustomDateMode.YearSelect) }}>
                        <CustomTypography variant="Regular20">{selectedYear?.title}</CustomTypography>
                        <Box sx={{ width: '32px', height: '32px', display: 'flex', justifyContent: 'center', alignItems: 'center', ":hover": { background: '#F9F9F9' } }}>
                            <ArrowDown />
                        </Box>
                    </Box>
                </Box>
            }
            {
                mode == CustomDateMode.MonthSelect &&
                <ControlledMonth
                    selectedMonthNumber={selectedMonthNumber}
                    selectedYearNumber={selectedYearNumber}
                    onSelectMonthNumber={onSelectMonthNumber}
                    onMonthChange={onMonthChange}
                    isOnlyMonthSelection={true}
                />
            }
            {
                mode == CustomDateMode.YearSelect &&
                <ControlledYear
                    onSelectYear={onSelectYearNumber}
                    selectedYearNumber={selectedYearNumber}
                    yearsList={yearsList}
                />
            }
            <Box className="date-picker-wrapper" sx={{ position: 'relative', display: mode == CustomDateMode.DateSelect ? undefined : 'none' }}>
                <DatePicker
                    onChange={handleChangeDate}
                    isOpen={true}
                    clearIcon={null}
                    value={date}
                    maxDate={new Date()}
                    calendarIcon={null}
                    maxDetail='month'
                    calendarProps={{
                        activeStartDate: activeStartDate,
                        onActiveStartDateChange: ({ action, activeStartDate, value, view }) => onActiveStartDateChange(activeStartDate)
                    }}
                />
            </Box>
        </>
    )
}