import { Box, InputAdornment, OutlinedInput } from '@mui/material';
import React, { Dispatch, SetStateAction, useState } from 'react'
import dateFormat from "dateformat";
import { SharedUtilities } from '@mprofit/shared';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import parser from "any-date-parser";

function DateInputField({ date, setDate, startDate, endDate }: { date: Date | undefined, setDate: Dispatch<SetStateAction<Date | undefined>>, endDate?: Date, startDate?: Date }) {
    const [inputDate, setInputDate] = useState<string>('');
    const [isEditing, setIsEditing] = useState(false);
    const [isInvalidDate, setIsInvalidDate] = useState(false);

    const yearList = SharedUtilities.getYearListForPeriodSelection();
    const minYear = Math.min(...yearList.map(item => item.year));
    const today = new Date();

    const validateDate = (parsedDate: Date): boolean => {
        if (isNaN(parsedDate.getTime())) return false;
        
        const isValidYear = parsedDate.getFullYear() >= minYear && parsedDate.getFullYear() <= today.getFullYear();
        const isNotFutureDate = parsedDate <= today;
        
        // Start/End date validation
        if (startDate && parsedDate < startDate) {
            return false; // End date can't be less than start date
        }
        if (endDate && parsedDate > endDate) {
            return false; // Start date can't be greater than end date
        }
        
        return isValidYear && isNotFutureDate;
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setInputDate(value);
        setIsInvalidDate(false);

        const parsedDate = parser.fromAny(value, "en-IN");
        if (parsedDate && validateDate(parsedDate)) {
            setDate(parsedDate > today ? today : parsedDate);
        } else {
            setIsInvalidDate(true);
        }
    };

    const handleInputFocus = () => {
        setIsEditing(true);
        setInputDate(date ? dateFormat(date, "dd/mm/yyyy") : '');
    };

    const handleInputBlur = () => {
        setIsInvalidDate(false);
        setIsEditing(false);
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <OutlinedInput
                size="small"
                sx={{
                    maxWidth: '180px',
                    color: date ? '#000 !important' : undefined,
                    "-webkit-text-fill-color": date ? '#000 !important' : undefined,
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: isInvalidDate ? '#BF1D1D !important' : undefined,
                    },
                }}
                id="input-with-icon-adornment"
                placeholder="DD/MM/YYYY"
                value={isEditing ? inputDate : (date ? dateFormat(date, "d mmm yyyy") : '')}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                onKeyDown={(e) => e.key === 'Enter' && e?.currentTarget?.blur()}
                startAdornment={
                    <InputAdornment position="start" sx={{ color: date ? '#000 !important' : undefined }}>
                        <CalendarTodayIcon />
                    </InputAdornment>
                }
                error={isInvalidDate}
            />
        </Box>
    )
}

export default DateInputField