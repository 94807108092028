import { Checkbox, FormControlLabel } from "@mui/material";
import { ChangeEvent } from "react";

interface CheckButtonProps {
  id?: string;
  checkLabel?: string;
  value?: string;
  handleClick?: (e: ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  indeterminate?: boolean;
}

export default function CheckButton({
  checkLabel,
  value,
  id,
  handleClick,
  checked,
  indeterminate,
}: CheckButtonProps) {
  return (
    <>
      {checkLabel ? (
        <FormControlLabel
          label={checkLabel}
          control={
            <Checkbox
              onChange={handleClick}
              name={checkLabel}
            />
          }
        />
      ) : (
        <Checkbox
          indeterminate={indeterminate}
          checked={checked}
          onChange={handleClick}
          disableRipple
          sx={{
            border: "5px",
            color: "#BABDCC",
            "&.Mui-checked": {
              color: "#5F854C",
            },
            "&.MuiCheckbox-indeterminate": {
              color: "#5F854C",
            },
          }}
          id={id}
          value={value}
        />
      )}
    </>
  );
}
