import { ColumnDataProps, TableDecimalFieldFormatter, TableDefaultFieldFormatter, TablePercentFieldFormatter } from '../shared/virtual-table-component';

type totlValueObj = {
  TopValue: string,
  BottomValue: string,
  TopValueColor: string,
  BottomValueColor: string,
}
export type dataPropType = {
  ASSET_CLASS: string,
  CURRENT_VALUE: string,
  GAIN: string,
  RELIAZED_GAIN: string,
  XIRR: totlValueObj
}

export const Tabledata = [{
  ASSET_CLASS: 'MUTUAL FUNDS',
  CURRENT_VALUE: '23123',
  GAIN: "34",
  RELIAZED_GAIN: "534",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "-123123",
    TopValueColor: "black",
    BottomValueColor: "red",
  }

},
{
  ASSET_CLASS: 'hgchcv',
  CURRENT_VALUE: '123123',
  GAIN: "qwe",
  RELIAZED_GAIN: "XIRR",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }
  ,

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

}
  ,
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }
},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }
},
{
  ASSET_CLASS: 'MUTUAL FUNDS',
  CURRENT_VALUE: '23123',
  GAIN: "34",
  RELIAZED_GAIN: "534",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "123123",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: 'hgchcv',
  CURRENT_VALUE: '123123',
  GAIN: "qwe",
  RELIAZED_GAIN: "XIRR",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }
  ,

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

}
  ,
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }


}, {
  ASSET_CLASS: 'MUTUAL FUNDS',
  CURRENT_VALUE: '23123',
  GAIN: "34",
  RELIAZED_GAIN: "534",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "123123",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: 'hgchcv',
  CURRENT_VALUE: '123123',
  GAIN: "qwe",
  RELIAZED_GAIN: "XIRR",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }
  ,

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }
},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }
},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }


}, {
  ASSET_CLASS: 'MUTUAL FUNDS',
  CURRENT_VALUE: '23123',
  GAIN: "34",
  RELIAZED_GAIN: "534",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "123123",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: 'hgchcv',
  CURRENT_VALUE: '123123',
  GAIN: "qwe",
  RELIAZED_GAIN: "XIRR",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }
  ,

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

}
  ,
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }


}, {
  ASSET_CLASS: 'MUTUAL FUNDS',
  CURRENT_VALUE: '23123',
  GAIN: "34",
  RELIAZED_GAIN: "534",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "123123",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: 'hgchcv',
  CURRENT_VALUE: '123123',
  GAIN: "qwe",
  RELIAZED_GAIN: "XIRR",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }
  ,

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

}
  ,
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }


}, {
  ASSET_CLASS: 'MUTUAL FUNDS',
  CURRENT_VALUE: '23123',
  GAIN: "34",
  RELIAZED_GAIN: "534",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "123123",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: 'hgchcv',
  CURRENT_VALUE: '123123',
  GAIN: "qwe",
  RELIAZED_GAIN: "XIRR",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }
  ,

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

}
  ,
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }


}, {
  ASSET_CLASS: 'MUTUAL FUNDS',
  CURRENT_VALUE: '23123',
  GAIN: "34",
  RELIAZED_GAIN: "534",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "123123",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: 'hgchcv',
  CURRENT_VALUE: '123123',
  GAIN: "qwe",
  RELIAZED_GAIN: "XIRR",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }
  ,

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

}
  ,
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }


}, {
  ASSET_CLASS: 'MUTUAL FUNDS',
  CURRENT_VALUE: '23123',
  GAIN: "34",
  RELIAZED_GAIN: "534",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "123123",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: 'hgchcv',
  CURRENT_VALUE: '123123',
  GAIN: "qwe",
  RELIAZED_GAIN: "XIRR",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }
  ,

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

}
  ,
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }


}, {
  ASSET_CLASS: 'MUTUAL FUNDS',
  CURRENT_VALUE: '23123',
  GAIN: "34",
  RELIAZED_GAIN: "534",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "123123",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: 'hgchcv',
  CURRENT_VALUE: '123123',
  GAIN: "qwe",
  RELIAZED_GAIN: "XIRR",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }
  ,

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

}
  ,
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }


},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

}
  ,
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }


}, {
  ASSET_CLASS: 'MUTUAL FUNDS',
  CURRENT_VALUE: '23123',
  GAIN: "34",
  RELIAZED_GAIN: "534",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "123123",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: 'hgchcv',
  CURRENT_VALUE: '123123',
  GAIN: "qwe",
  RELIAZED_GAIN: "XIRR",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }
  ,

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

}
  ,
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }


}, {
  ASSET_CLASS: 'MUTUAL FUNDS',
  CURRENT_VALUE: '23123',
  GAIN: "34",
  RELIAZED_GAIN: "534",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "123123",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: 'hgchcv',
  CURRENT_VALUE: '123123',
  GAIN: "qwe",
  RELIAZED_GAIN: "XIRR",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }
  ,

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

}
  ,
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }


}, {
  ASSET_CLASS: 'MUTUAL FUNDS',
  CURRENT_VALUE: '23123',
  GAIN: "34",
  RELIAZED_GAIN: "534",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "123123",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: 'hgchcv',
  CURRENT_VALUE: '123123',
  GAIN: "qwe",
  RELIAZED_GAIN: "XIRR",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }
},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }
},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }


}, {
  ASSET_CLASS: 'MUTUAL FUNDS',
  CURRENT_VALUE: '23123',
  GAIN: "34",
  RELIAZED_GAIN: "534",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "123123",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: 'hgchcv',
  CURRENT_VALUE: '123123',
  GAIN: "qwe",
  RELIAZED_GAIN: "XIRR",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }
  ,

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

}
  ,
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }


}, {
  ASSET_CLASS: 'MUTUAL FUNDS',
  CURRENT_VALUE: '23123',
  GAIN: "34",
  RELIAZED_GAIN: "534",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "123123",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: 'hgchcv',
  CURRENT_VALUE: '123123',
  GAIN: "qwe",
  RELIAZED_GAIN: "XIRR",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }
},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

}
  ,
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }

},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }


},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }


},
{
  ASSET_CLASS: "jhgljkg",
  CURRENT_VALUE: 'ee',
  GAIN: "eeeeeeee",
  RELIAZED_GAIN: "sds",
  XIRR: {
    TopValue: "123123qwe",
    BottomValue: "wqeqwe",
    TopValueColor: "black",
    BottomValueColor: "#5F854C",
  }


}
]

const commonTableColumns: ColumnDataProps[] = [
  {
    FieldKey: "InvAmt",
    HeaderName: "AMOUNT INVESTED",
    // ColumnWidthPercent: "15%",
    FieldFormatter: TableDecimalFieldFormatter,
    Align: "right",
  },
  {
    FieldKey: "OGain",
    HeaderName: "UNREALISED GAIN",
    // ColumnWidthPercent: "15%",
    FieldFormatter: TableDecimalFieldFormatter,
    Align: "right",
    subColumns: [
      {
        FieldKey: "OGainPct",
        FieldFormatter: TablePercentFieldFormatter,
        UpFieldKey: "OUp",
      },
    ],
  },
  {
    FieldKey: "CurrValue",
    HeaderName: "CURRENT VALUE",
    // ColumnWidthPercent: "15%",
    FieldFormatter: TableDecimalFieldFormatter,
    Align: "right",
  },
  {
    FieldKey: "HoldingPct",
    HeaderName: "HOLDING",
    // ColumnWidthPercent: "15%",
    FieldFormatter: TablePercentFieldFormatter,
    Align: "right",
  },
];

const tableColumns_byAssetAllocation: ColumnDataProps[] = [
  {
    FieldKey: "AssetTypeName",
    HeaderName: "ASSETS",
    FieldFormatter: TableDefaultFieldFormatter,
    IsRowName: true,
    ColumnWidthPercent: "25%",
    Align: "left",
  },
  ...commonTableColumns,
];

function Temp() {

  const MPRUrl = {
    Screen: 10,
    DatabaseID: 902031685,
    FamilyID: 1,
    PortfolioID: 1,
    AssetType: 0,
    AMID: 0,
    SID: 0,
    SectorID: 0,
    MarketCapID: 0,
    L3Mode: 0,
    AssetAllocationCategID: 0,
    SEBICategoryID: 0,
    SEBISubCategoryID: 0
  }

  return (
    <div className='temp-page'>
      <div style={{ margin: "10px 40px" }}>
      </div>
    </div>
  )
}

export default Temp;

