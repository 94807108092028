import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Radio,
  Slider,
  Stack,
  styled,
} from "@mui/material";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from "react";
import { CustomTypography } from "../../../shared";
import ButtonText from "../../../shared/button-text";
import ButtonPrimaryCTA from "../../../shared/button-primary-cta";
import {
  BlendedBenchmark,
  BlendItem,
} from "packages/shared/src/redux/modules/dashboardReducer";
import { TooltipDelete } from "packages/web/src/assets/icons/TooltipDelete";
import { BlendListItem, SearchHeader } from ".";
import { PlusIcon } from "packages/web/src/assets/icons/plusIcon";
import { SharedRedux, SharedUtilities } from "@mprofit/shared";
import { useAppDispatch, useAppSelector } from "packages/web/src/redux/hooks";
import CheckButton from "../../../shared/check-box";
import SearchBar from "../../../shared/search-bar";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ErrorIcon } from "packages/web/src/assets/icons/errorIcon";
import { BackArrow } from "packages/web/src/assets/icons/backArrow";
import { EditIcon } from "packages/web/src/assets/icons/editIcon";
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import Big from 'big.js';

type BlendViewState = "SAVED_BLENDS" | "CREATE_BLEND" | "SELECT_PORTION";

const BlendsView = React.memo(
  ({
    viewState,
    onCreateNewBlend,
    selectedItems,
    setSelectedItems,
    onSaveValues,
    handleBackButton,
    selectedBlendId,
    setSelectedBlendId,
    setIsModalOpen,
    setBlendViewState,
    onSaveBlend,
  }: {
    viewState: BlendViewState;
    onCreateNewBlend: () => void;
    selectedItems: number[];
    setSelectedItems: Dispatch<SetStateAction<number[]>>;
    onSaveValues: (values: BlendItem[], totalValue: number) => void;
    handleBackButton: () => void;
    selectedBlendId: string;
    setSelectedBlendId: (id: string) => void;
    setIsModalOpen: (isOpen: boolean) => void;
    setBlendViewState: Dispatch<SetStateAction<BlendViewState>>;
    onSaveBlend: () => void;
  }) => {
    switch (viewState) {
      case "CREATE_BLEND":
        return (
          <CreateBlendView
            setBlendViewState={setBlendViewState}
            setSelectedItems={setSelectedItems}
            initialSelectedItems={selectedItems}
          />
        );
      case "SELECT_PORTION":
        return (
          <SelectPortionView
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            onSaveValues={onSaveValues}
            handleBackButton={handleBackButton}
            setBlendViewState={setBlendViewState}
            onSaveBlend={onSaveBlend}
          />
        );
      default:
        return (
          <SavedBlendsView
            onCreateNewBlend={onCreateNewBlend}
            selectedBlendId={selectedBlendId}
            setSelectedBlendId={setSelectedBlendId}
            setIsModalOpen={setIsModalOpen}
          />
        );
    }
  }
);

const CreateBlendView = React.memo(
  ({
    setSelectedItems,
    setBlendViewState,
    initialSelectedItems,
  }: {
    setSelectedItems: Dispatch<SetStateAction<number[]>>;
    setBlendViewState: Dispatch<SetStateAction<BlendViewState>>;
    initialSelectedItems: number[];
  }) => {
    const [expanded, setExpanded] = useState<string | false>("panel1");
    const [selectedItem, setSelectedItem] = useState<number[]>(initialSelectedItems);

    const handleChange =
      (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        if (isExpanded) {
          setExpanded(panel);
        } else {
          setExpanded(panel === "panel1" ? "panel2" : "panel1");
        }
      };

    const handleSelectIndex = useCallback((mprofitCode: number) => {
      setSelectedItem((prev) => {
        if (prev.includes(mprofitCode)) {
          return prev.filter((item) => item !== mprofitCode);
        }
        if (prev.length < 5) {
          return [...prev, mprofitCode];
        }
        return prev;
      });
    }, []);

    const [searchTerm, setSearchTerm] = useState("");

    const indicesMaster = useAppSelector(
      SharedRedux.Dashboard.Selectors.selectIndicesForBlendedBenchmarksMaster
    );

    const filteredIndices = useMemo(
      () =>
        SharedUtilities.filterItems(searchTerm, indicesMaster, ["IndexName"]),
      [searchTerm, indicesMaster]
    );

    return (
      <Box sx={{
        flexWrap: "nowrap",
        flex: 1,
        minHeight: 0,
        display: "flex",
        flexDirection: "column",
        borderRadius: "8px",
      }}>
        <SearchHeader
          title="Create a new Blend"
          subtitle="Select up to 5 indices to create a Blended Benchmark for an XIRR comparison"
        >
          <Box>
            <SearchBar
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              isNonRoundedBorders={true}
              searchIconColor="#515466"
              sxProps={{
                "& .MuiInputBase-root": {
                  height: "48px",
                  paddingLeft: 0,
                  borderRadius: 0,
                },
                "& ::placeholder": {
                  color: "#515466",
                  opacity: "100%",
                },
              }}
            />
          </Box>
        </SearchHeader>
        <OverlayScrollbarsComponent style={{flex: 1}} defer options={{ scrollbars: { autoHide: 'scroll' } }}>
          <Box
          >
            {filteredIndices.map((indices, index) => (
              <Box
                key={index}
                onClick={() => handleSelectIndex(indices.MProfitCode)}
                sx={{
                  borderBottom: "1px solid #E8E9EF",
                  padding: "10px 14px",
                  alignItems: "center",
                  display: "flex",
                  backgroundColor: selectedItem.includes(
                    indices.MProfitCode
                  )
                    ? "#EAF4DF"
                    : "#FFFFFF",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#EAF4DF",
                  },
                }}
              >
                <CheckButton
                  id={`${indices.MProfitCode}`}
                  checked={selectedItem.includes(indices.MProfitCode)}
                />
                <CustomTypography
                  variant="Regular18"
                  color="#12131A"
                  sx={{ lineHeight: "24px" }}
                >
                  {indices.IndexName}
                </CustomTypography>
              </Box>
            ))}
          </Box>
        </OverlayScrollbarsComponent>
        <Box
          sx={{
            display: "flex",
            padding: "20px",
            alignItems: "center",
            justifyContent: "space-between",
            borderTop: "1px solid #EBECF2",
            position: "sticky",
            bottom: 0,
            zIndex: 1000,
            backgroundColor: "#fff",
          }}
        >
          <CustomTypography
            variant="Regular16"
            color="#64677A"
            sx={{
              lineHeight: "24px",
              marginLeft: "4px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <CustomTypography
              color="#12131A"
              mr={"4px"}
            >
              {selectedItem.length}
            </CustomTypography>
            of 5 selected
          </CustomTypography>
          <Box sx={{ display: "flex", alignItems: "center"}}>
            <Box mr={"12px"}>
              <ButtonText
                buttonText="Back"
                handleClick={() => {
                  setSelectedItem([]);
                  setSelectedItems([]);
                  setBlendViewState("SAVED_BLENDS");
                }}
                buttonSize="large"
              />
            </Box>
            <ButtonPrimaryCTA
              buttonText="Select"
              disabled={!selectedItem.length || selectedItem.length < 2 || selectedItem.length > 5}
              handleClick={() => {
                setSelectedItems(selectedItem);
                setBlendViewState("SELECT_PORTION");
              }}
            />
          </Box>
        </Box>
      </Box>
    );
  }
);

const SavedBlendsView = React.memo(({
  onCreateNewBlend,
  setIsModalOpen,
}: {
  onCreateNewBlend: () => void;
  selectedBlendId?: string;
  setSelectedBlendId?: (id: string) => void;
  setIsModalOpen: (isOpen: boolean) => void;
}) => {
  const dispatch = useAppDispatch();
  const savedBlends = useAppSelector(
    SharedRedux.Dashboard.Selectors.selectBlendedBenchmarks
  );
  const indicesForPerformanceMasterMapped = useAppSelector(
    SharedRedux.Dashboard.Selectors.selectIndicesForPerformanceMasterMapped
  );
  const activePortfolio = useAppSelector(
    SharedRedux.Portfolios.Selectors.selectActivePortfolio
  );

  const [selectedBlendId, setSelectedBlendId] = useState("");

  const handleDeleteBlend = (blendToDelete: BlendedBenchmark) => {
    dispatch(
      SharedRedux.Dashboard.Actions.deleteBlendedBenchmark({
        ObjectID: blendToDelete.ID,
        CCID: activePortfolio?.CCID || -1,
        FamilyId: activePortfolio?.FamilyId || -1,
      })
    );
  };

  const onBlendSelect = () => {
    dispatch(
      SharedRedux.Dashboard.Actions.setXIRRBlendedBenchmarkID({
        BlendedBenchmarkID: selectedBlendId,
      })
    );
    setIsModalOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid #CACBD1",
          padding: "16px 24px",
        }}
      >
        <Box>
          <CustomTypography
            variant="Regular20"
            color="#12131A"
            sx={{ lineHeight: "30px" }}
          >
            Saved Blends
          </CustomTypography>
          <CustomTypography
            variant="Regular16"
            color="#64677A"
            sx={{ lineHeight: "24px" }}
          >
            Select from your list of saved blends for an XIRR comparison
          </CustomTypography>
        </Box>
        <Button
            startIcon={<PlusIcon color="#64677A" />}
            onClick={onCreateNewBlend}
            sx={{
              border: "1px solid #D8DAE5",
              borderRadius: "4px",
              background: "#FFFFFF",
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "18.96px",
              textAlign: "center",
              color: "#64677A",
              padding: "20px 24px",
              height: "48px",
              textTransform: "none",
            }}
          >
            Create new Blend
        </Button>
      </Box>
      <OverlayScrollbarsComponent style={{flex: 1}} defer options={{ scrollbars: { autoHide: 'scroll' } }}>
        <Box
          sx={{
          flexWrap: "nowrap"
        }}
      >
        {savedBlends.map((blend) => (
          <Box
            key={blend.ID}
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            bgcolor={selectedBlendId === blend.ID ? "#EAF4DF" : "#FFFFFF"}
            sx={{ borderBottom: "1px solid #E8E9EF", paddingRight: "28px" }}
          >
            <Box
              display="flex"
              flexDirection="row"
              alignItems="flex-start"
              gap="24px"
              onClick={() => setSelectedBlendId(blend.ID)}
              sx={{ padding: "24px 0 24px 24px", cursor: "pointer", flex: 1 }}
            >
              <Radio
                sx={{
                  color: "#BABDCC",
                  "&.Mui-checked": {
                    color: "#5F854C",
                  },
                  height: "30px",
                  width: "30px",
                }}
                checked={selectedBlendId === blend.ID}
                onChange={() => setSelectedBlendId(blend.ID)}
              />
              <Box
                display="flex"
                flexDirection="row"
                gap="12px"
                alignItems="center"
                sx={{ flexWrap: "wrap", paddingRight: "40px" }}
              >
                {blend.Blend.map((item, index) => (
                  <BlendListItem
                    key={index}
                    title={
                      indicesForPerformanceMasterMapped[item.MProfitCode]
                        .IndexName
                    }
                    value={item.Percentage.toString()}
                    isSelected={selectedBlendId === blend.ID}
                  />
                ))}
              </Box>
            </Box>
            <Box
              sx={{
                cursor: "pointer",
                width: "24px",
                height: "24px",
                padding: "4px",
                marginTop: "24px",
              }}
              onClick={() => handleDeleteBlend(blend)}
            >
              <TooltipDelete />
            </Box>
          </Box>
        ))}
      </Box>
      </OverlayScrollbarsComponent>
      <Box
        sx={{
          display: "flex",
          padding: "20px",
          alignItems: "center",
          justifyContent: "flex-end",
          borderTop: "1px solid #EBECF2",
          borderRadius: "4px",
        }}
      >
        <ButtonText
          buttonText="Cancel"
          handleClick={() => setIsModalOpen(false)}
          buttonSize="large"
        />
        <Box ml={"12px"}>
          <ButtonPrimaryCTA
            buttonText="Select"
            handleClick={onBlendSelect}
            disabled={!selectedBlendId}
          />
        </Box>
      </Box>
    </>
  );
});

const SelectPortionView = React.memo(({
  selectedItems,
  setSelectedItems,
  onSaveValues,
  handleBackButton,
  setBlendViewState,
  onSaveBlend,
}: {
  selectedItems: number[];
  setSelectedItems: Dispatch<SetStateAction<number[]>>;
  onSaveValues: (values: BlendItem[], totalValue: number) => void;
  handleBackButton: () => void;
  setBlendViewState: Dispatch<SetStateAction<BlendViewState>>;
  onSaveBlend: () => void;
}) => {
  const indicesForPerformanceMasterMapped = useAppSelector(
    SharedRedux.Dashboard.Selectors.selectIndicesForPerformanceMasterMapped
  );
  const [values, setValues] = useState<BlendItem[]>([]);
  const [totalValue, setTotalValue] = useState(0);
  const [showError, setShowError] = useState(false);
  const roundedTotalValue = Math.round(totalValue);
  const [touchedIndices, setTouchedIndices] = useState<Set<number>>(new Set());

  useEffect(() => {
    if (selectedItems.length > 0) {
      const selectedIndices = selectedItems.map(
        (code) => indicesForPerformanceMasterMapped[code]
      );
      const initialValues = selectedIndices.map((index) => ({
        MProfitCode: index.MProfitCode,
        Percentage: selectedIndices.length <= 2 ? new Big(1).div(selectedIndices.length).toNumber() : 0,
      }));
      setValues(initialValues);
      const updatedTotal = initialValues.reduce((sum, item) => {
        return new Big(sum).plus(new Big(item.Percentage).times(100)).toNumber();
      }, 0);
      setTotalValue(updatedTotal);
      onSaveValues(
        initialValues,
        updatedTotal
      );
    }
  }, [selectedItems, indicesForPerformanceMasterMapped]);

  const updateTotalAndSave = (newValues: BlendItem[]) => {
    const newTotal = newValues.reduce((sum, item) => {
      return new Big(sum).plus(new Big(item.Percentage).times(100)).toNumber();
    }, 0);
    setTotalValue(newTotal);
    onSaveValues(newValues, newTotal);
    setShowError(Math.round(newTotal) !== 100);
  };

  const handleSliderChange = (index: number) => (_: Event, newValue: number | number[]) => {
    const newValues = [...values];
    newValues[index] = {
      ...newValues[index],
      Percentage: new Big(newValue as number).div(100).toNumber(),
    };
    setValues(newValues);
    updateTotalAndSave(newValues);
  };

  const handleSliderCommitted = (index: number) => {
    const newTouched = new Set(touchedIndices).add(index);
    if (newTouched.size === values.length - 1) {
      const untouchedIndex = values.findIndex((_, idx) => !newTouched.has(idx));
      if (untouchedIndex !== -1) {
        const totalPercentage = values.reduce(
          (sum, item, idx) => (
            idx !== untouchedIndex 
              ? new Big(sum).plus(item.Percentage).toNumber()
              : sum
          ),
          0
        );
        const newValues = [...values];
        newValues[untouchedIndex].Percentage = new Big(1)
          .minus(totalPercentage)
          .gt(0)
          ? new Big(1).minus(totalPercentage).toNumber()
          : 0;
        setValues(newValues);
        updateTotalAndSave(newValues);
      }
    }
    setTouchedIndices(newTouched);
  };

  return (
    <>
      <Box sx={{ padding: "16px 24px" }}>
        <Box
          display="flex"
          flexDirection="row"
          gap="20px"
        >
          <Box
            sx={{ cursor: "pointer", marginTop: "3px" }}
            onClick={handleBackButton}
          >
            <BackArrow />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
          >
            <CustomTypography
              variant="Regular20"
              color="#12131A"
              sx={{ lineHeight: "30px" }}
            >
              Select Blend Composition
            </CustomTypography>
            <CustomTypography
              variant="Regular16"
              color="#64677A"
              sx={{ lineHeight: "24px" }}
            >
              Set % proportions to be allocated to each index in your blended benchmark
            </CustomTypography>
          </Box>
        </Box>
      </Box>
      <OverlayScrollbarsComponent style={{flex: 1}} defer options={{ scrollbars: { autoHide: 'scroll' } }}>
        <Stack
        >
        {selectedItems.map((index, i) => (
          <Box
            key={i}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "16px 24px",
              borderTop: "1px solid #E8E9EF",
              borderBottom:
                i === selectedItems.length - 1 ? "1px solid #E8E9EF" : "none",
            }}
          >
            <CustomTypography
              variant="Regular16"
              color="#12131A"
            >
              {indicesForPerformanceMasterMapped[index]?.IndexName}
            </CustomTypography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                width="300px"
                mr="24px"
              >
                <CustomSlider
                  value={values[i]?.Percentage * 100}
                  onChange={handleSliderChange(i)}
                  onChangeCommitted={() => handleSliderCommitted(i)}
                  step={1}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "4px",
                  width: "50px",
                  padding: "12px 16px",
                  border: "1px solid #E8E9EF",
                }}
              >
                <CustomTypography variant="Regular16">
                  {new Big(values[i]?.Percentage || 0).times(100).toFixed(0)}%
                </CustomTypography>
              </Box>
            </Box>
          </Box>
        ))}
      </Stack>
      </OverlayScrollbarsComponent>
      <Box
        sx={{
          padding: "16px 24px",
          backgroundColor:
            showError && roundedTotalValue !== 100 ? "#FFF5F5" : "#F7F7F9",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          ...(showError && roundedTotalValue !== 100
            ? {
              borderTop: "1px solid #C94C40",
              borderBottom: "1px solid #C94C40",
            }
            : { border: "1px solid #D8DAE5" }),
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "95%",
            justifyContent: "space-between",
          }}
        >
          <CustomTypography
            variant="Regular14"
            color="#12131A"
          >
            TOTAL
          </CustomTypography>
          {showError && roundedTotalValue !== 100 && (
            <CustomTypography
              variant="Regular14"
              mr={"16px"}
            >
              Total allocation should be 100%. Please modify
            </CustomTypography>
          )}
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {showError && roundedTotalValue !== 100 && <ErrorIcon />}
          <CustomTypography
            variant="Medium18"
            color={
              showError && roundedTotalValue !== 100 ? "#C94C40" : "#12131A"
            }
            sx={{ ml: "4px", mr: "8px", width: "45px" }}
          >
            {new Big(totalValue).toFixed(0)}%
          </CustomTypography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          padding: "20px",
          alignItems: "center",
          justifyContent: "space-between",
          borderTop: "1px solid #EBECF2",
        }}
      >
        <ButtonText
          startIcon={<EditIcon color="#5F6F57" />}
          buttonText="Change Indices"
          handleClick={() => setBlendViewState("CREATE_BLEND")}
          buttonSize="large"
        />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ButtonText
            buttonText="Cancel"
            handleClick={() => {
              setSelectedItems([]);
              setBlendViewState("SAVED_BLENDS")
            }}
            buttonSize="large"
          />
          <Box ml={"12px"}>
            <ButtonPrimaryCTA
              buttonText="Save"
              handleClick={onSaveBlend}
              disabled={showError && roundedTotalValue !== 100}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
});

const SliderBoxShadow =
  "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

const CustomSlider = styled(Slider)(({ theme }) => ({
  color: "#5F854C",
  height: 5,
  padding: "15px 0",
  "& .MuiSlider-thumb": {
    height: 20,
    width: 20,
    backgroundColor: "#fff",
    boxShadow: "0 0 2px 0px rgba(0, 0, 0, 0.1)",
    "&:focus, &:hover, &.Mui-active": {
      boxShadow: "0px 0px 3px 1px rgba(0, 0, 0, 0.1)",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        boxShadow: SliderBoxShadow,
      },
    },
    "&:before": {
      boxShadow:
        "0px 0px 1px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 1px 0px rgba(0,0,0,0.12)",
    },
  },
  "& .MuiSlider-valueLabel": {
    fontSize: 12,
    fontWeight: "normal",
    top: -6,
    backgroundColor: "unset",
    color: theme.palette.text.primary,
    "&::before": {
      display: "none",
    },
    "& *": {
      background: "transparent",
      color: "#000",
    },
  },
  "& .MuiSlider-track": {
    border: "none",
    height: 5,
  },
  "& .MuiSlider-rail": {
    opacity: 1,
    backgroundColor: "rgba(120, 120, 128, 0.20)",
  },
}));

export default BlendsView;
