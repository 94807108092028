import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { MProfitAPI, ResponseGenerator } from "../../api";
import { ActiveScreenPayload, FetchPortfoliosPayload, Actions as PortfolioActions, SetActivePortfolioPayload } from "../modules/portfoliosReducer";
import { Actions, BlendedBenchmark, SaveBlendPayload, Selectors, WatchlistPayload } from "../modules/dashboardReducer";
import { Actions as PerformanceActions, Selectors as PerformanceSelectors, RefreshXIRRPayload } from "../modules/performanceReducer";
import { Selectors as PortfolioSelectors } from "../modules/portfoliosReducer";
import type { PayloadAction } from "@reduxjs/toolkit";
import { HttpStatusCode } from "axios";
import { Selectors as LoginSelectors } from "../modules/loginReducer";
import { AssetTypeEnum, PriceValues, WatchlistTypeEnum, WatchlistEditItem, IndexMaster, AM_PeriodRequest, AdvancedPerformanceChartMode, WatchlistItem } from "../../constants";
import { fetchPrices } from "./portfolioSummarySaga";

function* onSetActivePortfolio(action: PayloadAction<SetActivePortfolioPayload>) {
    if (action.payload.FamilyId > 0) {
        yield put(Actions.refreshCorporateActions(action.payload));
    }
}

const corporateActionsAPI = (payload: SetActivePortfolioPayload) => {
    return MProfitAPI.get('/api/Dashboard/CorporateActions', {
        params: {
            ccid: payload.CCID,
            familyId: payload.FamilyId,
            pfid: payload.PFID
        }
    });
}

const watchlistListAPI = (payload: WatchlistPayload) => {
    return MProfitAPI.get('/api/Watchlist/List', {
        params: {
            ccid: payload?.CCID || -1,
            watchListType: payload?.WatchlistType,
            watchlistID: payload?.WatchlistID
        }
    });
}

const searchWatchlistListAPI = (term: string) => {
    return MProfitAPI.get('/api/Form/FindAssets', {
        params: {
            term,
            assetTypeId: AssetTypeEnum.EQ,
        }
    });
}

function* onRefreshWatchListPrices() {
    while (true) {
        var priceURLs: string[] = yield select(Selectors.selectWatchlistPriceURLs);

        var assetType: AssetTypeEnum = AssetTypeEnum.EQ;

        let priceValues: PriceValues = yield call(fetchPrices, { [assetType]: priceURLs });

        yield put(Actions.setWatchlistPriceValues(priceValues[assetType]));

        yield delay(60000);
    }
}


function* onRefreshWatchList() {
    var ccid: number = yield select(LoginSelectors.selectUserCCID);
    const response: ResponseGenerator = yield call(watchlistListAPI, { WatchlistType: WatchlistTypeEnum.SystemStocks, CCID: ccid });
    if (response.status == HttpStatusCode.Ok) {
        if (response.data == null) {
            var updateResponse: ResponseGenerator = yield call(updateWatchListAPI, [{ MProfitCode: 100240 }, { MProfitCode: 104511 }, { MProfitCode: 100128 }, { MProfitCode: 104268 }, { MProfitCode: 100365 }]);

            if (updateResponse.status == HttpStatusCode.Ok) {
                yield put(Actions.refreshWatchList());
            }
        } else {
            yield put(Actions.setWatchList(response.data));

            yield put(Actions.refreshWatchlistPrices());
        }
    } else {
        // 
    }

}

function* onRefreshSearchWatchList(action: PayloadAction<string>) {
    const response: ResponseGenerator = yield call(searchWatchlistListAPI, action.payload);
    if (response.status == HttpStatusCode.Ok) {
        yield put(Actions.setSearchWatchList(response.data));
    } else {
        // 
    }

}
const addItemToWatchListAPI = (payload: WatchlistEditItem) => {
    return MProfitAPI.post('/api/Watchlist/Add',
        payload
    );
}

const removeItemFromWatchListAPI = (payload: WatchlistEditItem) => {
    return MProfitAPI.post('/api/Watchlist/Remove',
        payload
    );
}

const updateWatchListAPI = (payload: WatchlistEditItem[]) => {
    return MProfitAPI.post('/api/Watchlist/Update',
        payload
    );
}

function* onUpdateWatchList(action: PayloadAction<WatchlistEditItem[]>) {
    const response: ResponseGenerator = yield call(updateWatchListAPI, action.payload)
    if (response.status == HttpStatusCode.Ok) {
        yield put(Actions.refreshWatchList());
        return null
    } else {
        // 
    }
}

function* onAddItemToWatchList(action: PayloadAction<WatchlistEditItem>) {
    const response: ResponseGenerator = yield call(addItemToWatchListAPI, action.payload)
    if (response.status == HttpStatusCode.Ok) {
        // yield put(Actions.refreshWatchList('a'));
        return null
    } else {
        // 
    }
}

function* onRemoveItemFromWatchListAPI(payload: PayloadAction<WatchlistEditItem>) {
    const response: ResponseGenerator = yield call(removeItemFromWatchListAPI, payload.payload)
    if (response.status == HttpStatusCode.Ok) {
        yield put(Actions.refreshWatchList());
        return null
    } else {
        // 
    }
}

function* onRefreshCorporateActions(action: PayloadAction<SetActivePortfolioPayload>) {
    const response: ResponseGenerator = yield call(corporateActionsAPI, action.payload);

    if (response.status == HttpStatusCode.Ok) {
        yield put(Actions.setCorporateActions(response.data));
    } else {
        // 
    }
}

const indicesListAPI = (payload: WatchlistPayload) => {
    return MProfitAPI.get('/api/Indices/List', {
        params: {
            ccid: payload.CCID,
            watchListType: payload.WatchlistType,
        }
    });
}

const updateIndicesWatchlistAPI = (indices: { MProfitCode: number, IsPinned?: boolean }[], payload: WatchlistPayload) => {
    return MProfitAPI.post('/api/Indices/Update', indices, {
        params: {
            ccid: payload.CCID,
            watchListType: payload.WatchlistType,
        }
    });
}

function* onRefreshIndices() {
    var ccid: number = yield select(LoginSelectors.selectUserCCID);
    const response: ResponseGenerator = yield call(indicesListAPI, { WatchlistType: WatchlistTypeEnum.SystemIndices, CCID: ccid });

    if (response.status == HttpStatusCode.Ok) {
        if (response.data == null) {
            var updateResponse: ResponseGenerator = yield call(updateIndicesWatchlistAPI, [{ MProfitCode: 99999 }, { MProfitCode: 99998 }, { MProfitCode: 99992 }, { MProfitCode: 99991 }, { MProfitCode: 99954 }], { WatchlistType: WatchlistTypeEnum.SystemIndices, CCID: ccid });

            if (updateResponse.status == HttpStatusCode.Ok) {
                yield put(Actions.refreshIndices());
            }
        } else {
            yield put(Actions.setIndicesWatchlist(response.data));

            yield put(Actions.refreshIndicesPrices());
        }
    } else {
        // 
    }

    return true;
}

function* onRefreshPerformanceIndices(action: PayloadAction<{ IsUserUpdate: boolean }>) {
    var ccid: number = yield select(LoginSelectors.selectUserCCID);
    const response: ResponseGenerator = yield call(indicesListAPI, { WatchlistType: WatchlistTypeEnum.PerformanceIndices, CCID: ccid });

    if (response.status == HttpStatusCode.Ok) {
        if (response.data == null) {
            var updateResponse: ResponseGenerator = yield call(updateIndicesWatchlistAPI, [{ MProfitCode: 99999 }, { MProfitCode: 99998 }, { MProfitCode: 99992 }, { MProfitCode: 99991 }, { MProfitCode: 99906 }], { WatchlistType: WatchlistTypeEnum.PerformanceIndices, CCID: ccid });

            if (updateResponse.status == HttpStatusCode.Ok) {
                yield put(Actions.refreshPerformanceIndices({}));
            }
        } else {
            yield put(Actions.setPerformanceIndicesWatchlist(response.data));

            if (action.payload.IsUserUpdate === true) {
                const indexReturnsPeriodRequest: AM_PeriodRequest | undefined = yield select(PerformanceSelectors.selectXIRRTotalIndexReturnsPeriodRequest);
                if (indexReturnsPeriodRequest) {
                    yield put(PerformanceActions.refreshXIRRTotalIndexReturns(indexReturnsPeriodRequest));
                }
            }
        }
    } else {
        // 
    }

    return true;
}

function* onUpdateIndicesWatchList(action: PayloadAction<{ WatchlistType: WatchlistTypeEnum, Indices: { [MProfitCode: number]: boolean } }>) {
    var ccid: number = yield select(LoginSelectors.selectUserCCID);
    if (!action.payload.Indices) return;

    var indices = [];
    for (const [key, value] of Object.entries(action.payload.Indices)) {
        if (value === true) {
            indices.push({ MProfitCode: +key });
        }
    }

    if (indices.length <= 0) return;

    indices = indices.reverse();

    var payload = { CCID: ccid, WatchlistType: action.payload.WatchlistType }
    const response: ResponseGenerator = yield call(updateIndicesWatchlistAPI, indices, payload);
    if (response.status == HttpStatusCode.Ok) {
        if (action.payload.WatchlistType == WatchlistTypeEnum.PerformanceIndices) {
            yield put(Actions.refreshPerformanceIndices({ IsUserUpdate: true }));
        } else {
            yield put(Actions.refreshIndices());
        }
        return null
    } else {
        // 
    }
}

function* onRefreshIndicesPrices(action: PayloadAction) {
    while (true) {
        var priceURLs: string[] = yield select(Selectors.selectIndicesWatchlistPriceURLs);

        var assetType: AssetTypeEnum = AssetTypeEnum.EQ;

        let priceValues: PriceValues = yield call(fetchPrices, { [assetType]: priceURLs });

        yield put(Actions.setIndicesPriceValues(priceValues[assetType]));

        yield delay(60000);
    }
}

function* onSetPerformanceSingleIndex(action: PayloadAction<{ AdvancedPerformanceChartMode?: AdvancedPerformanceChartMode }>) {
    if (action.payload.AdvancedPerformanceChartMode) {
        if (action.payload.AdvancedPerformanceChartMode === AdvancedPerformanceChartMode.YearWise) {
            const indexReturnsPeriodRequests: AM_PeriodRequest[] | undefined = yield select(PerformanceSelectors.selectXIRRYearWiseIndexReturnsPeriodRequests);
            if (indexReturnsPeriodRequests) {
                yield put(PerformanceActions.refreshXIRRYearWiseIndexReturns(indexReturnsPeriodRequests));
            }
        } else if (action.payload.AdvancedPerformanceChartMode === AdvancedPerformanceChartMode.FYWise) {
            const indexReturnsPeriodRequests: AM_PeriodRequest[] | undefined = yield select(PerformanceSelectors.selectXIRRFYWiseIndexReturnsPeriodRequests);
            if (indexReturnsPeriodRequests) {
                yield put(PerformanceActions.refreshXIRRFYWiseIndexReturns(indexReturnsPeriodRequests));
            }
        }
    }
}

function* onSetXIRRBenchmarkSingleIndex(action: PayloadAction<{ IndexItem: WatchlistItem, NoRefresh?: boolean }>) {
    yield put(Actions.setXIRRBlendedBenchmarkID({ BlendedBenchmarkID: undefined }));

    var activeScreenIDs: ActiveScreenPayload = yield select(PortfolioSelectors.selectActiveScreenIDs);
    var refreshXIRRPayload: RefreshXIRRPayload = {
        Force: false,
        NoRefresh: false,
        CCID: activeScreenIDs.DatabaseID || -1,
        FamilyId: activeScreenIDs.FamilyID || -1,
        PFID: activeScreenIDs.PortfolioID || -1,
        ATTY: activeScreenIDs.AssetType || -1,
        AssetAllocationCategID: activeScreenIDs.AssetAllocationCategID || -1,
        AMID: activeScreenIDs.AMID || -1,
        SID: activeScreenIDs.SID || -1,
    }

    if (action.payload.NoRefresh !== true) {
        yield put(PerformanceActions.refreshXIRRBenchmark(refreshXIRRPayload));
    }
}

const blendedBenchmarkListAPI = (payload: { CCID: number, FamilyId: number }) => {
    return MProfitAPI.get('/api/Performance/BlendedBenchmark/List', {
        params: {
            ccid: payload.CCID,
            familyId: payload.FamilyId
        }
    });
}

function* onRefreshBlendedBenchmarks(action: PayloadAction<{ CCID: number, FamilyId: number }>) {
    const response: ResponseGenerator = yield call(blendedBenchmarkListAPI, action.payload);

    if (response.status === HttpStatusCode.Ok) {
        yield put(Actions.setBlendedBenchmarks(response.data as BlendedBenchmark[]));
    }
}

const saveBlendedBenchmarkAPI = (payload: SaveBlendPayload) => {
    return MProfitAPI.post('/api/Performance/BlendedBenchmark/AddOrUpdate',
        {
            Blend: payload.Blend,
            SortOrder: payload.SortOrder
        },
        {
            params: {
                ccid: payload.CCID,
                familyId: payload.FamilyId
            }
        }
    );
}

function* onSaveBlendedBenchmark(action: PayloadAction<SaveBlendPayload>) {
    const response: ResponseGenerator = yield call(saveBlendedBenchmarkAPI, action.payload);

    if (response.status === HttpStatusCode.Ok && response.data.IsSuccess) {
        yield put(Actions.refreshBlendedBenchmarks({
            CCID: action.payload.CCID,
            FamilyId: action.payload.FamilyId
        }));
        yield put(Actions.setXIRRBlendedBenchmarkID({ BlendedBenchmarkID: response.data.ObjectID }));
        yield put(Actions.setXIRRBlendedBenchmarkStatus({ IsSuccess: true, Message: '' }))
    } else {
        yield put(Actions.setXIRRBlendedBenchmarkStatus({ IsSuccess: false, Message: response.data.ErrorMessage }))
    }

    return null;
}

function* onSetXIRRBlendedBenchmarkID(action: PayloadAction<{ BlendedBenchmarkID?: string, NoRefresh?: boolean }>) {
    if (action.payload.BlendedBenchmarkID === undefined) {
        return null;
    }

    if (action.payload.NoRefresh !== true) {
        var activeScreenIDs: ActiveScreenPayload = yield select(PortfolioSelectors.selectActiveScreenIDs);
        var refreshXIRRPayload: RefreshXIRRPayload = {
            Force: false,
            NoRefresh: false,
            CCID: activeScreenIDs.DatabaseID || -1,
            FamilyId: activeScreenIDs.FamilyID || -1,
            PFID: activeScreenIDs.PortfolioID || -1,
            ATTY: activeScreenIDs.AssetType || -1,
            AssetAllocationCategID: activeScreenIDs.AssetAllocationCategID || -1,
            AMID: activeScreenIDs.AMID || -1,
            SID: activeScreenIDs.SID || -1,
            BlendedBenchmarkId: action.payload.BlendedBenchmarkID
        };

        yield put(PerformanceActions.refreshXIRRBenchmark(refreshXIRRPayload));
    }
}

const deleteBlendedBenchmarkAPI = (objectID: string) => {
    return MProfitAPI.post('/api/Performance/BlendedBenchmark/Delete', null, {
        params: {
            objectID
        }
    });
}

function* onDeleteBlendedBenchmark(action: PayloadAction<{ ObjectID: string, CCID: number, FamilyId: number }>) {
    const response: ResponseGenerator = yield call(deleteBlendedBenchmarkAPI, action.payload.ObjectID);

    if (response.status === HttpStatusCode.Ok) {
        yield put(Actions.refreshBlendedBenchmarks({
            CCID: action.payload.CCID,
            FamilyId: action.payload.FamilyId
        }));
        var blendedBenchmarkID: string | undefined = yield select(Selectors.selectXIRRBlendedBenchmarkID);
        if (blendedBenchmarkID === action.payload.ObjectID) {
            yield put(Actions.setXIRRBlendedBenchmarkID({ BlendedBenchmarkID: undefined }));
        }
    }

    return null;
}

function* dashboardSaga() {
    yield takeLatest(PortfolioActions.setActivePortfolio.type, onSetActivePortfolio);
    yield takeLatest(Actions.refreshCorporateActions.type, onRefreshCorporateActions);
    yield takeLatest(Actions.refreshIndices.type, onRefreshIndices);
    yield takeLatest(Actions.refreshIndicesPrices.type, onRefreshIndicesPrices);
    yield takeLatest(Actions.refreshPerformanceIndices.type, onRefreshPerformanceIndices);
    yield takeLatest(Actions.refreshWatchList.type, onRefreshWatchList);
    yield takeLatest(Actions.refreshSearchWatchlistListAPI.type, onRefreshSearchWatchList);
    yield takeLatest(Actions.addItemToWatchList.type, onAddItemToWatchList);
    yield takeLatest(Actions.removeItemFromWatchList.type, onRemoveItemFromWatchListAPI);
    yield takeLatest(Actions.refreshWatchlistPrices.type, onRefreshWatchListPrices);
    yield takeLatest(Actions.updateWatchList.type, onUpdateWatchList);
    yield takeLatest(Actions.updateIndicesWatchlist.type, onUpdateIndicesWatchList);
    yield takeLatest(Actions.setPerformanceSingleIndex.type, onSetPerformanceSingleIndex);
    yield takeLatest(Actions.setXIRRBenchmarkSingleIndex.type, onSetXIRRBenchmarkSingleIndex)
    yield takeLatest(Actions.refreshBlendedBenchmarks.type, onRefreshBlendedBenchmarks);
    yield takeLatest(Actions.saveBlendedBenchmark.type, onSaveBlendedBenchmark);
    yield takeLatest(Actions.setXIRRBlendedBenchmarkID.type, onSetXIRRBlendedBenchmarkID);
    yield takeLatest(Actions.deleteBlendedBenchmark.type, onDeleteBlendedBenchmark);
}

export default dashboardSaga;



