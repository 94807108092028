export function BackArrow(props: any) {
 return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Frame" clip-path="url(#clip0_272_14160)">
    <g id="Group 149757">
    <path id="Path 235639" d="M6.75 15.75L3 12M3 12L6.75 8.25M3 12H21" stroke="#64677A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    </g>
    <defs>
    <clipPath id="clip0_272_14160">
    <rect width="24" height="24" fill="white"/>
    </clipPath>
    </defs>
    </svg>    
 );
}
