import { Box, Container, Divider, Grid, Stack } from "@mui/material";
import { CloseIcon } from "packages/web/src/assets/icons/CloseIcon";
import { useState } from "react";
import ButtonPrimaryCTA from "../button-primary-cta";
import ButtonText from "../button-text";
import CustomTypography from "../customTypography";
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import { ArrowRight } from "packages/web/src/assets/icons/global";
import CustomDate from "./custom-date";
import Month, { SetMonthProps } from "./month";
import FinancialYear from "./financial-year";
import { SharedConstants, formatDateForReading } from "@mprofit/shared";
import Year, { SetYearProps } from "./year";
import TrailingPeriod from "./trailing-period";
import CustomDateSelector from "./date";
import DateInputField from "./date-input-field";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

interface ITimePeriodProps {
    closePopOver?: () => void,
    onSetPeriod?: (period: SharedConstants.SetPeriodPayload) => void
}

const modes = [
    {
        id: SharedConstants.PeriodSelectorMode.ALLTODATE,
        text: 'All to Date'
    },
    {
        id: SharedConstants.PeriodSelectorMode.ALLTOCUSTOMDATE,
        text: 'All to Custom Date'
    },
    {
        id: SharedConstants.PeriodSelectorMode.FY,
        text: 'Financial Year'
    },
    {
        id: SharedConstants.PeriodSelectorMode.YEAR,
        text: 'Year'
    },
    {
        id: SharedConstants.PeriodSelectorMode.MONTH,
        text: 'Month'
    },
    {
        id: SharedConstants.PeriodSelectorMode.TRAILING,
        text: 'Trailing'
    },
    {
        id: SharedConstants.PeriodSelectorMode.CUSTOM,
        text: 'Custom'
    }
]

export default function PeriodSelector({ closePopOver = () => { }, onSetPeriod = () => { } }: ITimePeriodProps) {
    const [selectedFY, setSelectedFY] = useState<SharedConstants.FYListItem | undefined>(undefined);
    const [selectedTrailingPeriod, setSelectedTrailingPeriod] = useState<SharedConstants.TrailingPeriodItem | undefined>(undefined);
    const [selectedMonth, setSelectedMonth] = useState<SetMonthProps | undefined>(undefined);
    const [selectedYear, setSelectedYear] = useState<SetYearProps | undefined>(undefined);
    const [allToCustomDate, setAllToCustomDate] = useState<Date | undefined>(new Date());

    const [customStartDate, setCustomStartDate] = useState<Date>();
    const [customEndDate, setCustomEndDate] = useState<Date | undefined>(undefined);

    const [activeMode, setActiveMode] = useState(SharedConstants.PeriodSelectorMode.NONE);

    const onDoneClicked = () => {
        switch (activeMode) {
            case SharedConstants.PeriodSelectorMode.FY:
                if (selectedFY) {
                    onSetPeriod({PeriodFromValue: selectedFY.startDate, PeriodToValue: selectedFY.endDate, PeriodText: selectedFY.fyName, IsAllToDate: false});
                }
                break;
            case SharedConstants.PeriodSelectorMode.MONTH:
                if (selectedMonth) {
                    onSetPeriod({PeriodFromValue: selectedMonth.startDate, PeriodToValue: selectedMonth.endDate, PeriodText: selectedMonth.title, IsAllToDate: false});
                }
                break;
            case SharedConstants.PeriodSelectorMode.YEAR:
                if (selectedYear) {
                    onSetPeriod({PeriodFromValue: selectedYear.startDate, PeriodToValue: selectedYear.endDate, PeriodText: selectedYear.title, IsAllToDate: false});
                }
                break;
            case SharedConstants.PeriodSelectorMode.ALLTODATE:
                onSetPeriod({PeriodFromValue: undefined, PeriodToValue: undefined, PeriodText: 'All to Date', IsAllToDate: true});
                break;
            case SharedConstants.PeriodSelectorMode.CUSTOM:
                if (customStartDate && customEndDate) {
                    onSetPeriod({PeriodFromValue: customStartDate, PeriodToValue: customEndDate, PeriodText: `${formatDateForReading(customStartDate)} to ${formatDateForReading(customEndDate)}`, IsAllToDate: false});
                }
                break;
            case SharedConstants.PeriodSelectorMode.TRAILING:
                if (selectedTrailingPeriod) {
                    onSetPeriod({PeriodFromValue: selectedTrailingPeriod.startDate, PeriodToValue: selectedTrailingPeriod.endDate, PeriodText: selectedTrailingPeriod.title, IsAllToDate: false});
                }
                break;
            case SharedConstants.PeriodSelectorMode.ALLTOCUSTOMDATE:
                if (allToCustomDate) {
                    onSetPeriod({PeriodFromValue: undefined, PeriodToValue: allToCustomDate, PeriodText: `All to ${formatDateForReading(allToCustomDate)}`, IsAllToDate: false});
                }
                break;
        }

        closePopOver();
    }

    const getSelectedDisplayText = () => {
        switch (activeMode) {
            case SharedConstants.PeriodSelectorMode.FY:
                return selectedFY ? selectedFY.title : '';
            case SharedConstants.PeriodSelectorMode.MONTH:
                return selectedMonth ? selectedMonth.title : '';
            case SharedConstants.PeriodSelectorMode.YEAR:
                return selectedYear ? selectedYear.title : '';
            case SharedConstants.PeriodSelectorMode.TRAILING:
                return selectedTrailingPeriod ? selectedTrailingPeriod.title : '';
            case SharedConstants.PeriodSelectorMode.ALLTODATE:
                return 'All to Date';
        }
    }

    return (
        <Stack sx={{ width: "768px", paddingTop: '24px' }} >
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={5.5} sx={{ borderRight: '1px solid #EBECF2', height: 477 }}>
                        <Grid container >
                            <Grid item xs={12}>
                                <CustomTypography variant="Regular18" sx={{ paddingBottom: '16px' }}>
                                    Select a time period
                                </CustomTypography>
                            </Grid>
                            <Grid item xs={12} overflow={'auto'} maxHeight={'440px'}>
                                <OverlayScrollbarsComponent defer options={{ scrollbars: { autoHide: 'scroll' } }}>
                                    {modes.map((mode) => (
                                        <Box
                                            key={`menu-${mode.id}`}
                                            sx={{
                                                border: '1px solid #EBECF2',
                                                padding: '14px 27px 14px 16px',
                                                marginRight: '16px',
                                                marginBottom: "16px",
                                                borderRadius: '4px',
                                                background: activeMode === mode.id ? '#12131A' : "#FFFFFF",
                                                color: activeMode === mode.id ? "#FFFFFF" : "#12131A",
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => setActiveMode(mode.id)}
                                        >
                                            <CustomTypography variant="Regular20" sx={{ color: activeMode === mode.id ? "#FFFFFF" : "#12131A" }}>{mode.text}</CustomTypography>
                                        </Box>
                                    ))
                                    }
                                </OverlayScrollbarsComponent>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6.5} sx={{ display: 'flex', flexDirection: 'column', height: 485 }}>
                        <Grid container sx={{ flex: '0 0 60px' }}>
                            <Grid item xs={12}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', }}>
                                    <CustomTypography variant="Medium24">
                                        {getActionText(activeMode)}
                                    </CustomTypography>
                                    <CloseIcon onClick={closePopOver} style={{ cursor: 'pointer' }} />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{
                            overflowY: 'auto', overflowX: 'hidden',
                            margin: '0px 10px 0px 16px',
                            '::-webkit-scrollbar': {
                                width: '1px',
                            }
                        }}>
                            {(() => {
                                switch (activeMode) {
                                    case SharedConstants.PeriodSelectorMode.FY:
                                        return <FinancialYear
                                            setSelectedFY={setSelectedFY}
                                        />
                                    case SharedConstants.PeriodSelectorMode.YEAR:
                                        return <Year
                                            setSelectedYear={setSelectedYear}
                                            // selectedYear={selectedYear}
                                            // setSelectedYear={setSelectedYear}
                                            // selectedMonth={selectedMonth}
                                            // handleSelectedMonth={handleSelectedMonth}
                                        />
                                    case SharedConstants.PeriodSelectorMode.MONTH:
                                        return <Month
                                            setSelectedMonth={setSelectedMonth}
                                            // selectedYear={selectedYear}
                                            // setSelectedYear={setSelectedYear}
                                            // selectedMonth={selectedMonth}
                                            // handleSelectedMonth={handleSelectedMonth}
                                        />
                                    case SharedConstants.PeriodSelectorMode.ALLTODATE:
                                        return <CustomDate
                                            isAllToDate={true}
                                            startDate={new Date(1970, 4, 1)}
                                            endDate={new Date()}
                                        />
                                    case SharedConstants.PeriodSelectorMode.CUSTOM:
                                        return <CustomDate
                                            setStartDate={setCustomStartDate}
                                            setEndDate={setCustomEndDate}
                                            // setEndDateActive={setEndDateActive}
                                            // endDateActive={endDateActive}
                                            startDate={customStartDate || null}
                                            endDate={customEndDate || null}
                                        />
                                    case SharedConstants.PeriodSelectorMode.TRAILING:
                                        return <TrailingPeriod
                                            setSelectedTrailingPeriod={setSelectedTrailingPeriod}
                                            />
                                    case SharedConstants.PeriodSelectorMode.ALLTOCUSTOMDATE:
                                         return <CustomDateSelector
                                                date={allToCustomDate}
                                                setDate={setAllToCustomDate}
                                                />
                                    default:
                                        return null
                                }
                            })()}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Box >
                            <Divider />
                            <Box sx={{ display: 'flex', padding: '16px', justifyContent: 'space-between', alignItems: 'center' }}>
                                {
                                    activeMode === SharedConstants.PeriodSelectorMode.CUSTOM ?
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                             <DateInputField date={customStartDate} setDate={setCustomStartDate} endDate={customEndDate}/>
                                            <Box sx={{ margin: '0px 8px' }}>
                                                <ArrowRight />
                                            </Box>
                                             <DateInputField date={customEndDate} setDate={setCustomEndDate} startDate={customStartDate}/>
                                        </Box>
                                        :
                                        activeMode === SharedConstants.PeriodSelectorMode.ALLTOCUSTOMDATE ?
                                        <DateInputField date={allToCustomDate} setDate={setAllToCustomDate}/>
                                            :
                                            <Box>
                                                <CustomTypography variant="Regular18">{getSelectedDisplayText()}</CustomTypography>
                                            </Box>
                                }
                                <Box display={"flex"}>
                                    <ButtonText buttonText="Cancel" buttonSize="large" handleClick={closePopOver} />
                                    <Box paddingLeft="16px">
                                        <ButtonPrimaryCTA buttonText="Done" handleClick={onDoneClicked} />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Stack >
    )
}

const getActionText = (activeMode: SharedConstants.PeriodSelectorMode) => {
    switch (activeMode) {
        case SharedConstants.PeriodSelectorMode.FY:
            return 'Select Financial Year';
        case SharedConstants.PeriodSelectorMode.MONTH:
            return 'Select Month';
        case SharedConstants.PeriodSelectorMode.YEAR:
            return 'Select Year';
        case SharedConstants.PeriodSelectorMode.CUSTOM:
            return 'Select Dates';
        case SharedConstants.PeriodSelectorMode.ALLTODATE:
            return 'All to Date';
        case SharedConstants.PeriodSelectorMode.TRAILING:
            return 'Select Period';
        case SharedConstants.PeriodSelectorMode.ALLTOCUSTOMDATE:
            return 'All to Custom Date';
    }

    return '';
}