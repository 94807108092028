import { call, put, select, takeLatest } from "redux-saga/effects";
import type { PayloadAction } from "@reduxjs/toolkit";
import { HttpStatusCode } from "axios";
import { Actions as LoginActions, Selectors as LoginSelectors } from "../modules/loginReducer";
import { Actions as DashboardActions } from "../modules/dashboardReducer";
import { Actions as PortfolioActions, Selectors as PortfolioSelectors, SetActivePortfolioPayload } from "../modules/portfoliosReducer";
import { MProfitAPI, ResponseGenerator } from "../../api";
import { DatabaseItem } from "../../constants";

const getDatabasesAPI = () => {
    return MProfitAPI.get('/api/UserAccess/GetDatabases?isAnalytics=true');
};

function* getDatabasesInfo() {
    const response: ResponseGenerator = yield call(getDatabasesAPI);
    if (response.status === HttpStatusCode.Ok) {
        yield put(PortfolioActions.setDatabases(response.data));
    } else {
        console.error("Failed to fetch end client : ", response);
    }
}

function* onLoadDashboard(action: PayloadAction<LoadDashboardPayload>) {
    yield call(getDatabasesInfo);

    const databases: DatabaseItem[] = yield select(PortfolioSelectors.selectDatabases);
    console.log("🚀 ~ function*onLoadDashboard ~ databases:", databases);

    var CCIDToLoad = action.payload.CCID > 0 || (databases == null || databases.length === 0) ? action.payload.CCID : +(databases[0].CustomerId);

    var activePortfolio = action.payload.ActivePortfolio;

    if (action.payload.CCID > 0 && !(databases == null || databases.length === 0)) {
        var activeDatabase = databases.find(x => x.CustomerId === '' + action.payload.CCID);
        if (!activeDatabase) {
            CCIDToLoad = +(databases[0].CustomerId);
            activePortfolio = undefined;
        }
    }

    console.log(CCIDToLoad, 'CCIDToLoad');

    if (CCIDToLoad <= 0) {
        return;
    }
    
    yield put(LoginActions.refreshLicenseInfo());

    yield put(PortfolioActions.fetchPortfolios({ IsLaunch: true, CCID: CCIDToLoad, ActivePortfolio: activePortfolio, OnNavigateToPortfolio: action.payload.OnNavigateToPortfolio }));

    yield put(DashboardActions.refreshIndices());

    yield put(DashboardActions.refreshWatchList());

    yield put(DashboardActions.refreshPerformanceIndices({}));

    yield put(DashboardActions.refreshIndicesMaster());

    yield put(DashboardActions.refreshIndicesForPerformanceMaster());

    yield put(DashboardActions.fetchUserProfile());

    yield put(LoginActions.getAccessRights());
    
    yield put(DashboardActions.refreshBlendedBenchmarks({ CCID: CCIDToLoad, FamilyId: activePortfolio?.FamilyId || -1 }));
    
}

function* loadingSaga() {
    yield takeLatest(LoginActions.loadDashboard.type, onLoadDashboard);
}

export interface LoadDashboardPayload {
    CCID: number,
    ActivePortfolio?: SetActivePortfolioPayload,
    OnNavigateToPortfolio(activePortfolio: SetActivePortfolioPayload): void
}

export default loadingSaga;



