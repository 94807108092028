export enum SortDirection {
    Up = 1,
    Down = -1
}

export enum AdvancedPerformanceChartMode {
    YearWise = 1,
    FYWise = 2
}

export enum AdvancedPerformanceTableMode {
    Summary = 1,
    Details = 2
}

export enum PeriodSelectorMode {
    NONE = -1,
    FY = 0,
    MONTH = 1,
    ALLTODATE = 2,
    CUSTOM = 3,
    YEAR = 4,
    TRAILING = 5,
    ALLTOCUSTOMDATE = 6
}