import { Box, Button, Divider, FormControlLabel, Radio, RadioGroup, Stack } from "@mui/material";
import { t } from "i18next";
import { CloseIcon } from "packages/web/src/assets/icons/CloseIcon";
import CustomModal from "../../../shared/custom-modal";
import CustomTypography from "../../../shared/customTypography";
import styles from "./index.module.css";
import { useAppDispatch, useAppSelector } from "packages/web/src/redux/hooks";
import { SharedConstants, SharedRedux, SharedUtilities } from "@mprofit/shared";
import React, { useState } from "react";
import ButtonText from "../../../shared/button-text";
import ButtonPrimaryCTA from "../../../shared/button-primary-cta";
import { IndexMaster } from "packages/shared/src/constants";
import SearchBar from "../../../shared/search-bar";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
const modalData = ['Sensex', 'FTSE 100', 'S&P 50', 'Nifty Midcap', 'Nifty', 'NASDAQ', 'Nifty Bank', 'Nifty IT', 'Nifty Midcap', 'Nifty', 'NASDAQ', 'Nifty Bank', 'Nifty IT']

interface ChangeSingleIndexModalContentProps {
    setIsModalOpen: (isOpen: boolean) => void;
    initialActiveSingleIndex?: SharedConstants.WatchlistItem;
    activePerfChartMode?: SharedConstants.AdvancedPerformanceChartMode;
    source?: SharedConstants.ChartType;
}

interface ChangeSingleIndexModalProps extends ChangeSingleIndexModalContentProps {
    isModalOpen: boolean;
}

export const ChangeSingleIndexModal = React.memo(function ChangeSingleIndexModal({isModalOpen, setIsModalOpen, initialActiveSingleIndex, activePerfChartMode, source}: ChangeSingleIndexModalProps) {
    return <CustomModal open={isModalOpen} handleClose={() => setIsModalOpen(false)}>
        <ChangeSingleIndexModalContent {...{setIsModalOpen, initialActiveSingleIndex, activePerfChartMode, source}}/>
      </CustomModal>
});

function ChangeSingleIndexModalContent({ setIsModalOpen, initialActiveSingleIndex, activePerfChartMode, source }: ChangeSingleIndexModalContentProps) {
    const indicesMaster = useAppSelector(SharedRedux.Dashboard.Selectors.selectIndicesForPerformanceMaster);

    const [activeIndexItem, setActiveIndexItem] = useState<IndexMaster | undefined>(initialActiveSingleIndex ? {MProfitCode: initialActiveSingleIndex.MProfitCode, IndexName: initialActiveSingleIndex.AssetName} : undefined);

    const [searchTerm, setSearchTerm] = useState('');

    const dispatch = useAppDispatch();
    const onSave = () => {
        if (activeIndexItem) {
            if (source === SharedConstants.ChartTypes.XIRR_BENCHMARK_CHART) {
                dispatch(SharedRedux.Dashboard.Actions.setXIRRBenchmarkSingleIndex({
                    IndexItem: {
                        AssetName: activeIndexItem.IndexName,
                        MProfitCode: activeIndexItem.MProfitCode
                    },
                }));
            } else {
                dispatch(SharedRedux.Dashboard.Actions.setPerformanceSingleIndex({
                    IndexItem: {
                        AssetName: activeIndexItem.IndexName,
                        MProfitCode: activeIndexItem.MProfitCode
                    },
                    AdvancedPerformanceChartMode: activePerfChartMode
                }));
            }
        }
        setIsModalOpen(false);
    }

    const finalList = SharedUtilities.filterItems(searchTerm, indicesMaster, ['IndexName']);
    
    return (
        <Stack className={styles.modalContainer} sx={{width: {xl: '34%', lg: '50%', xs: '75%'}, position: 'relative'}}>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    padding: '24px 0px 0px 24px',
                    boxSizing: 'border-box'
                }}>
                <CustomTypography variant="Medium24">{'Select Index'}</CustomTypography>
                <Box sx={{ marginLeft: 'auto', right: '24px', cursor: 'pointer', position: 'absolute', top: '24px' }} onClick={() => setIsModalOpen(false)}>
                    <CloseIcon sx={{ color: '#707070'}} />
                </Box>
            </Box>
            <CustomTypography
                variant="Regular16"
                sx={{
                    color: { xs: '#64677A' },
                    paddingLeft: '24px'
                }}>
                    {'Select an index to compare your XIRR'}
            </CustomTypography>
            <Box sx={{marginTop: '20px', marginBottom: '10px'}}>
                <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} isNonRoundedBorders={true}/>
            </Box>
            <OverlayScrollbarsComponent defer options={{ scrollbars: { autoHide: 'scroll' } }}>
            <RadioGroup sx={{
                flexWrap: 'nowrap',
                height: '600px'
            }}>
                {finalList.map((indexItem, index) => (
                    <Box key={index} sx={{'&:hover': {cursor: 'pointer', backgroundColor: "#EAF4DF"}}} onClick={() => setActiveIndexItem(indexItem)}>
                        <FormControlLabel
                            value={indexItem.MProfitCode}
                            sx={{
                                marginRight: '0px',
                                color: '#12131A',
                                fontSize: '18px',
                                padding: '10px',
                                '& .MuiFormControlLabel-label': { fontSize: '18px' },
                            }}
                            control={
                                <Radio
                                    sx={{
                                        marginLeft: '14px',
                                        color: '#BABDCC',
                                        '&.Mui-checked': {
                                            color: '#5F854C',
                                        },
                                    }}
                                    checked={activeIndexItem?.MProfitCode === indexItem.MProfitCode}
                                    onChange={() => setActiveIndexItem(indexItem)}
                                />
                            }
                            label={indexItem.IndexName}
                        />
                        <Divider
                            sx={{ width: '84%', margin: 'auto' }} />
                    </Box>
                ))}

            </RadioGroup>
            </OverlayScrollbarsComponent>
            <Box className={styles.modalFooter}>
                <ButtonText buttonText={t("portfolio.performance.sharedComponents.footerButton.cancel")} handleClick={() => setIsModalOpen(false)} buttonSize="large" />
                <ButtonPrimaryCTA buttonText={t("portfolio.performance.sharedComponents.footerButton.done")} handleClick={() => onSave()} />
                {/* <Button
                    sx={{
                        color: '#5F6F57',
                        fontSize: '16px',
                        fontWeight: 600,
                        backgroundColor: 'white',
                        padding: '10px 24px',
                        marginRight: '8px',
                        textTransform: 'none'
                    }}
                    onClick={() => setIsModalOpen(false)}
                >{t("portfolio.performance.sharedComponents.footerButton.cancel")}</Button> */}
                {/* <Button
                    sx={{
                        color: '#ffffff', fontWeight: 600, padding: '10px 24px', backgroundColor: 'black', textTransform: 'none',
                        '&:hover': { backgroundColor: '#12131A' },
                        '&:focus': { backgroundColor: '#12131A' },
                        '&:active': { backgroundColor: '#12131A' }
                    }}
                    onClick={() => setIsModalOpen(false)}>{t("portfolio.performance.sharedComponents.footerButton.done")}</Button> */}
            </Box>
        </Stack>
    )
}

export default ChangeSingleIndexModal;
