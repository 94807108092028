import {
  Box,
  Divider,
  Stack,
} from "@mui/material";
import { CloseIcon } from "packages/web/src/assets/icons/CloseIcon";
import CustomModal from "../../../shared/custom-modal";
import CustomTypography from "../../../shared/customTypography";
import styles from "./index.module.css";
import { useAppDispatch, useAppSelector } from "packages/web/src/redux/hooks";
import { SharedConstants, SharedRedux, SharedUtilities } from "@mprofit/shared";
import React, {
  MouseEvent,
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from "react";
import NavigationTabs from "../../../shared/navigation-tabs";
import { memo } from "react";
import { BlendItem } from "packages/shared/src/redux/modules/dashboardReducer";
import { SingleIndexView } from "./singleIndexView";
import BlendsView from "./blendsView";
import Big from 'big.js';

interface ChangeBlendedIndexModalContentProps {
  setIsModalOpen: (isOpen: boolean) => void;
  initialActiveSingleIndex?: SharedConstants.WatchlistItem;
  source?: SharedConstants.ChartType;
}

interface ChangeSingleIndexModalProps
  extends ChangeBlendedIndexModalContentProps {
  isModalOpen: boolean;
  IsBlendedBenchmarkAllowed?: boolean;
}

const commonStyles = {
  padding24: { padding: "24px 0px 0px 24px" },
  modalWidth: { width: { xs: "75%", lg: "60%", xl: "44%" }, minWidth: "620px" },
};

const TABS = {
  SINGLE_INDEX: 1,
  BLENDS: 2,
} as const;

export const useSearchableIndices = () => {
  const indicesMaster = useAppSelector(
    SharedRedux.Dashboard.Selectors.selectIndicesForPerformanceMaster
  );
  const [searchTerm, setSearchTerm] = useState("");

  const filteredIndices = useMemo(
    () => SharedUtilities.filterItems(searchTerm, indicesMaster, ["IndexName"]),
    [searchTerm, indicesMaster]
  );

  return {
    searchTerm,
    setSearchTerm,
    filteredIndices,
    indicesMaster,
  };
};

export const SearchHeader = memo(
  ({
    title,
    subtitle,
    children,
  }: {
    title: string;
    subtitle: string;
    children?: React.ReactNode;
  }) => (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          padding: "16px 0px 0px 24px",
          boxSizing: "border-box",
        }}
      >
        <CustomTypography variant="Regular20">{title}</CustomTypography>
      </Box>
      <CustomTypography
        variant="Regular16"
        sx={{
          color: { xs: "#64677A" },
          padding: "0px 0px 16px 24px",
        }}
      >
        {subtitle}
      </CustomTypography>
      {children}
    </>
  )
);

export const BlendListItem = ({
  title,
  value,
  isSelected,
}: {
  title: string;
  value: string;
  isSelected: boolean;
}) => {
  const percentageValue = (parseFloat(value) * 100).toFixed(0);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid #D8DAE5",
        borderRadius: "6px",
        padding: "4px 8px",
        gap: "12px",
        backgroundColor: isSelected ? "#5F6F57" : "#FFFFFF",
      }}
    >
      <CustomTypography
        variant={isSelected ? "Medium16" : "Regular16"}
        color={isSelected ? "#FFFFFF" : "#64677A"}
        sx={{ lineHeight: "24px", textTransform: "capitalize" }}
      >
        {title}
      </CustomTypography>
      <CustomTypography
        variant="Regular16"
        color={isSelected ? "#FFFFFF" : "#12131A"}
        sx={{ lineHeight: "24px", textTransform: "capitalize" }}
      >
        {percentageValue}%
      </CustomTypography>
    </Box>
  );
};

type BlendViewState = "SAVED_BLENDS" | "CREATE_BLEND" | "SELECT_PORTION";

const SingleIndexViewMemo = React.memo(SingleIndexView);
const tabs = [TABS.SINGLE_INDEX, TABS.BLENDS];

export const ChangeBlendedIndexModal = React.memo(
  function ChangeBlendedIndexModal({
    isModalOpen,
    setIsModalOpen,
    initialActiveSingleIndex,
    IsBlendedBenchmarkAllowed,
    source,
  }: ChangeSingleIndexModalProps) {
    const [activeTab, setActiveTab] = useState<
      (typeof TABS)[keyof typeof TABS]
    >(TABS.SINGLE_INDEX);

    const [blendViewState, setBlendViewState] =
      useState<BlendViewState>("SAVED_BLENDS");
    const [selectedItems, setSelectedItems] = useState<number[]>([]);
    const [selectedBlendId, setSelectedBlendId] = useState<string>("");
    const dispatch = useAppDispatch();

    const activePortfolio = useAppSelector(
      SharedRedux.Portfolios.Selectors.selectActivePortfolio
    );
    const xirrBlendendBenchmarkStatus = useAppSelector(SharedRedux.Dashboard.Selectors.selectXIRRBlendedBenchmarkStatus);

    const currentValuesRef = useRef<{
      values: BlendItem[];
      totalValue: number;
    }>({
      values: [],
      totalValue: 0,
    });

    const handleTabChange = (event: MouseEvent<HTMLElement>, newValue: any) => {
      newValue && setActiveTab(newValue);
    };

    const handleClose = useCallback(
      () => {
        setIsModalOpen(false);
        setTimeout(() => {
          setBlendViewState("SAVED_BLENDS");
          setSelectedItems([]);
          currentValuesRef.current = { values: [], totalValue: 0 };
          dispatch(SharedRedux.Dashboard.Actions.setXIRRBlendedBenchmarkStatus({ IsSuccess: false, Message: '' }));
        }, 300)
      },
      [setIsModalOpen, dispatch]
    );

    const handleCreateNewBlend = () => {
      setBlendViewState("CREATE_BLEND");
    };

    const handleEditIndices = () => {
      setBlendViewState("CREATE_BLEND");
    };

    useEffect(() => {
      if (xirrBlendendBenchmarkStatus?.IsSuccess) {
        handleClose();
      }
    }, [xirrBlendendBenchmarkStatus, handleClose])

    const onSaveBlend = () => {
      const { values, totalValue } = currentValuesRef.current;

      if (!activePortfolio?.CCID || !activePortfolio?.FamilyId) return;

      const roundedTotal = Math.round(totalValue);
      if (roundedTotal !== 100) {
        return;
      }

      const blendPayload = {
        Blend: values.map((item) => ({
          MProfitCode: item.MProfitCode,
          Percentage: new Big(item.Percentage).round(2).toNumber(),
        })),
        CCID: activePortfolio.CCID,
        FamilyId: activePortfolio.FamilyId,
        SortOrder: 0,
      };
      dispatch(
        SharedRedux.Dashboard.Actions.saveBlendedBenchmark(blendPayload)
      );
    };

    const handleSaveValues = (values: BlendItem[], totalValue: number) => {
      currentValuesRef.current = { values, totalValue };
    };

    return (
      <CustomModal
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
      >
        <Stack
          className={styles.modalContainer}
          sx={{ ...commonStyles.modalWidth, position: "relative" }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "24px 0px 0px 24px",
            }}
          >
            <Box
              sx={{
                alignSelf: "center",
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <NavigationTabs
                tabIDs={tabs}
                tabTexts={{
                  [TABS.SINGLE_INDEX]: "Single Index",
                  [TABS.BLENDS]: "Blends",
                }}
                disabledTabs={{
                  [TABS.SINGLE_INDEX]: false,
                  [TABS.BLENDS]: IsBlendedBenchmarkAllowed === false,
                }}
                handleChange={handleTabChange}
                activeID={activeTab}
                sxProps={{
                  width: "48%",
                  display: "grid",
                  gridTemplateColumns: `repeat(${tabs.length}, 1fr)`,
                  borderRadius: "4px",
                }}
              />
            </Box>
            <Box
              sx={{
                marginLeft: "auto",
                cursor: "pointer",
                position: "absolute",
                right: "24px",
              }}
              onClick={() => setIsModalOpen(false)}
            >
              <CloseIcon sx={{ color: "#707070" }} />
            </Box>
          </Box>
          <Divider
            sx={{ width: "100%", mt: "24px", backgroundColor: "#CACBD1" }}
          />
          {activeTab === TABS.SINGLE_INDEX ? (
            <SingleIndexViewMemo
              initialActiveSingleIndex={initialActiveSingleIndex}
              handleClose={handleClose}
            />
          ) : (
            <BlendsView
              viewState={blendViewState}
              onCreateNewBlend={handleCreateNewBlend}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              onSaveValues={handleSaveValues}
              handleBackButton={handleEditIndices}
              selectedBlendId={selectedBlendId}
              setSelectedBlendId={setSelectedBlendId}
              setIsModalOpen={setIsModalOpen}
              setBlendViewState={setBlendViewState}
              onSaveBlend={onSaveBlend}
            />
          )}
        </Stack>
      </CustomModal>
    );
  }
);

export default ChangeBlendedIndexModal;
